import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {YesNoPipe} from './yes-no.pipe';
import {FiletypePipe} from './filetype.pipe';
import {UserTypePipe} from "./user-type.pipe";
import {StatusPipe} from "./status.pipe";
import {OrderStatusPipe} from "./order-status.pipe";

@NgModule({
    declarations: [
        YesNoPipe,
        FiletypePipe,
        UserTypePipe,
        StatusPipe,
        OrderStatusPipe,
    ],
    imports: [
        CommonModule
    ],
    exports: [
        YesNoPipe,
        UserTypePipe,
        FiletypePipe,
        StatusPipe,
        OrderStatusPipe,
    ]
})
export class PipesModule {
}
