import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'orderStatus',
})
export class OrderStatusPipe implements PipeTransform {

    transform(type: string, ...args: unknown[]): string {
        switch (type) {
            case 'new':
                return 'Neu';
            case 'completed':
                return 'Erledigt';
            default:
                return type;
        }
    }

}
