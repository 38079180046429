import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userType',
})
export class UserTypePipe implements PipeTransform {

  transform(type: string, ...args: unknown[]): string {
      switch (type) {
          case 'ADMIN':
              return 'Admin';
          case 'EDITOR':
              return 'Bearbeiter';
          case 'WAREHOUSE':
              return 'Lagerarbeiter';
          default:
              return type;
      }
  }

}
