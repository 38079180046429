import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'status',
})
export class StatusPipe implements PipeTransform {

    transform(type: string, ...args: unknown[]): string {
        switch (type) {
            case 'new':
                return 'Neu';
            case 'scheduled':
                return 'Disponiert';
            case 'arrived':
                return 'Eingelangt';
            case 'completed':
                return 'Abgeschlossen';
            default:
                return type;
        }
    }

}
